<template>
  <div class="confirm-slots">
    <div>
      <time-left />
      <div class="confirm-header">
        <iq-title :class="{ desktopHeader: !isMobile, mobileHeader: isMobile }">
          Подтвердить тайм слоты:
        </iq-title>
        <p class="confirm-subtitle">
          Добавьте авто и нажмите
          <span style="color: #eb6626">"Подтвердить тайм слоты"</span>
        </p>
      </div>
      <div v-if="requireInn" class="m-b-16 m-t-16 confirm-requireInn">
        <div class="m-b-7">
          <span>ИНН поставщика/перевозчика:&nbsp;</span>{{ requireInn }}
        </div>
        <div>
          <i>{{ requireInnName }}</i>
        </div>
      </div>
      <div v-for="(item, i) in getSloatPriceData" :key="i">
        <div v-if="item['timeslot_id']" class="btn-container">
          <span class="label-slot">
            <span>
              {{ `Авто №${i + 1}` }}
            </span>
            <span class="label-slot-date">
              {{
                `${dateTimeslot(item.timeslot.window_from)} c ${timeTimeslot(
                  item.timeslot.window_from,
                )} до ${timeTimeslot(item.timeslot.window_to)} :`
              }}
            </span>
          </span>

          <div class="slot-line">
            <el-button
              class="btn-quota btn-full"
              :disabled="
                !!confirmSlot(item.timeslot_id) ||
                !!deletedSlot(item.timeslot_id)
              "
              plain
              :type="
                deletedSlot(item.timeslot_id)
                  ? 'danger'
                  : getCarNumber(item.timeslot_id) ||
                    confirmSlot(item.timeslot_id)
                  ? 'success'
                  : ''
              "
              @click="routerPush(item.timeslot_id)"
            >
              <i class="el-icon-truck icon-btn" />
              <span v-if="!!deletedSlot(item.timeslot_id)">Запрос отменен</span>
              <span
                v-if="
                  !confirmSlot(item.timeslot_id) &&
                  !deletedSlot(item.timeslot_id)
                "
              >
                {{
                  getCarNumber(item.timeslot_id) || ' Введите данные автомобиля'
                }}
              </span>
              <span v-if="confirmSlot(item.timeslot_id)">
                {{ 'Тайм слот подтвержден' }}
              </span>
            </el-button>
            <el-button
              v-if="!confirmSlot(item.timeslot_id)"
              type="danger"
              class="btn-quota btn-icon btn-delete"
              @click="openDelete(item.timeslot_id)"
            >
              <i class="el-icon-delete red-btn" />
            </el-button>
          </div>
        </div>
        <span v-if="item['state_system'] === 'no_quota'">
          <span>
            {{ `Авто №${i + 1} нет тайм слота ` }}
          </span>
          <span class="no-quota">Нет квоты</span>
        </span>
      </div>
    </div>
    <div class="btn-container">
      <iq-button
        v-if="showConfirmButton"
        size="fullWidth"
        @onClick="submitForm"
      >
        Подтвердить все тайм слоты
      </iq-button>
      <div style="margin-top: 22px; width: 100%">
        <iq-button full-width color="second" size="fullWidth" @onClick="open">
          Вернуться к поиску тайм слотов
        </iq-button>
      </div>
      <confirm-modal
        v-if="timeSlotBeforeSubmit.length > 0 && showConfirmModal"
        :dialog-visible="timeSlotBeforeSubmit.length > 0 && showConfirmModal"
        :dialog-data-slots="getSloatPriceData"
        :dialog-data="timeSlotBeforeSubmit"
        @close="closeConfirmModal"
      />
    </div>
  </div>
</template>

<script>
import {
  DR_GET_FULL_FORM_WITH_AUTO,
  DR_GET_LOADINGS,
  DR_POST_TIMESLOTS_SUBMIT,
  DR_UPDATE_RUN_TIMER,
} from '@/store/actions'
import {
  confirmedTimeslotDate,
  confirmedTimeslotTime,
  numAutoValidator,
  removeTerminalId,
} from '@/core'
import { mapActions, mapGetters } from 'vuex'
import ConfirmModal from '../modals/ConfirmModal'
import IqButton from '../../ui/components/buttons/IqButton'
import IqTitle from '../../ui/components/typography/IqTitle'
import TimeLeft from '@/views/drivers-home/components/TimeLeft'

export default {
  name: 'ConfirmSlots',
  components: {
    TimeLeft,
    IqTitle,
    IqButton,
    ConfirmModal,
  },
  props: {
    getSloatPriceData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showConfirmModal: false,
      confirmAnswer: null,
      timeSlotBeforeSubmit: [],
      allowConfirm: 0,
      currentTime: 0,
      timer: null,
      hideConfirm: false,
      noQuotaItems: [],
      rules: [
        {
          required: true,
          message: 'Заполните номер авто',
          trigger: 'blur',
        },
        {
          required: true,
          validator: (rule, value, callback) => {
            numAutoValidator(value) ? callback(new Error()) : callback()
          },
          message:
            'Формат номера должен быть "м213тт99" или "1212ттт" или "e2233кк"',
          trigger: 'blur',
        },
      ],
      dateTimeslot: confirmedTimeslotDate,
      timeTimeslot: confirmedTimeslotTime,
    }
  },
  computed: {
    ...mapGetters({
      loading: DR_GET_LOADINGS,
      formData: DR_GET_FULL_FORM_WITH_AUTO,
    }),
    slotForm() {
      return JSON.parse(localStorage.getItem('get-slot-form'))
    },

    showConfirmButton() {
      let slots = this.getSloatPriceData.filter(
        item => item['state_system'] === 'time_given',
      )

      return slots.length > 0
    },

    requireInn() {
      return (
        this.getSloatPriceData[0].timeslot?.extras?.common
          ?.common_supplier_inn || ''
      )
    },
    requireInnName() {
      return (
        this.getSloatPriceData[0].timeslot?.extras?.common
          ?.common_supplier_name || ''
      )
    },
  },

  methods: {
    ...mapActions({
      submitTimeslots: DR_POST_TIMESLOTS_SUBMIT,
      updateRunTime: DR_UPDATE_RUN_TIMER,
    }),
    closeConfirmModal(chosen) {
      this.timeSlotBeforeSubmit = []
      this.confirmAnswer = chosen
      this.confirmAnswer ? this.submitForm() : null
    },
    routerPush(id) {
      this.$router.push({ name: 'ConfirmAddAuto', params: { id } })
    },
    deletedSlot(timeslot_id) {
      const index = this.getSloatPriceData.findIndex(
        i => String(i.timeslot_id) === String(timeslot_id),
      )

      return index !== -1 && this.getSloatPriceData[index].timeslot?.deleted_at
    },
    confirmSlot(timeslot_id) {
      const index = this.getSloatPriceData.findIndex(
        i => String(i.timeslot_id) === String(timeslot_id),
      )

      return (
        index !== -1 &&
        this.getSloatPriceData[index]['state_system'] === 'time_submitted' &&
        !this.getSloatPriceData[index].timeslot?.deleted_at
      )
    },
    getCarNumber(id) {
      if (this.formData.length > 0) {
        let timeslot = this.formData.filter(
          item => String(item.timeslot_id) === String(id),
        )

        if (timeslot.length > 0) {
          return ' Авто №' + timeslot[0].plate_truck
        }
      }

      return null
    },
    open() {
      if (this.getSloatPriceData.length === 0) {
        removeTerminalId()
        this.cancelSubmit()
        this.updateRunTime(false)

        return
      }
      this.$confirm(
        'Вы действительно хотите отказаться от тайм слотов?',
        'Внимание!',
        {
          confirmButtonText: 'Да',
          cancelButtonText: 'Нет',
          type: 'warning',
          customClass: 'confirm-modal',
          confirmButtonClass: 'confirmDelete',
        },
      )
        .then(() => {
          removeTerminalId()
          this.cancelSubmit()
          this.updateRunTime(false)

          this.$message({
            type: 'danger',
            message: 'Вы отказались от тайм слотов',
            customClass: 'driver-message',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Отказ от тайм слотов не подтвержден',
            customClass: 'driver-message',
          })
        })
    },
    cancelSubmit() {
      const activeTimeSlot = this.getSloatPriceData.filter(
        item => item?.state_system === 'time_given',
      )

      activeTimeSlot.map(item => {
        if (item.timeslot_id) {
          item.submit = 0
          this.submitTimeslots(item)
        }
      })
    },

    submitForm() {
      this.timeSlotBeforeSubmit = []

      if (this.formData.length > 0) {
        this.confirmAnswer
          ? (this.showConfirmModal = false)
          : (this.showConfirmModal = true)
        this.formData.map(item => {
          let notSubmittedSlot = this.getSloatPriceData.filter(
            i => String(i.timeslot_id) === String(item.timeslot_id),
          )

          if (notSubmittedSlot[0].state_system === 'time_given') {
            this.timeSlotBeforeSubmit.push(item)
          }
        })

        if (this.confirmAnswer) {
          this.timeSlotBeforeSubmit.forEach(item =>
            this.submitTimeslots({
              ...item,
              common_supplier_id: this.slotForm?.common_supplier_id || null,
            }),
          )
        }
      } else {
        this.$message({
          type: 'info',
          message: 'Выберите авто для таймслота',
          customClass: 'driver-message',
        })
      }
    },
    submitTimeslot(id) {
      this.formData.map(item => {
        if (String(item.timeslot_id) === String(id)) {
          this.submitTimeslots(item)
        }
      })
    },

    openDelete(id) {
      this.$confirm(
        'Вы действительно хотите отказаться от тайм слота?',
        'Внимание!',
        {
          confirmButtonText: 'Да, хочу отказаться',
          cancelButtonText: 'Нет, не хочу',
          type: 'warning',
          customClass: 'confirm-modal',
          confirmButtonClass: 'confirmDelete',
        },
      )
        .then(() => {
          this.cancelSubmitSlot(id)

          this.$message({
            type: 'danger',
            message: 'Вы отказались от тайм слота',
            customClass: 'driver-message',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Отказ от тайм слотов не подтвержден',
            customClass: 'driver-message',
          })
        })
    },
    cancelSubmitSlot(id) {
      let deleteTimeslot

      this.getSloatPriceData.map(item => {
        if (String(item.timeslot_id) === String(id)) {
          deleteTimeslot = item
        }
      })
      deleteTimeslot.submit = 0
      this.submitTimeslots(deleteTimeslot)
    },
  },
}
</script>

<style lang="sass">
$greyBackColor: #E8E8E8
$blueBackColor: #C1E1EC
$mainFont: #606266
$greenColor: #008400
$redColor: red
.slot-line
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%
  .btn-full
    display: inline-block
    flex: 1 0

.confirm-slots
  .drivers-slots-form
  background: white
  font-weight: normal
  font-size: $fs-14
  line-height: $lh-16
  position: relative
  .el-form-item
    margin-bottom: 19px
    &__label
      text-align: left
      line-height: 22px
      font-weight: bold
      width: 100%
      padding: 0 !important
  .el-date-editor
    width: 100% !important
  +to(550px)
    .el-form-item__label
      min-width: 300px!important
  .el-form-item__content
    line-height: 32px
  .el-select
    width: 100%
  .el-input__icon:before
    font-size: 14px
.line
  display: flex
  justify-content: center
  align-items: center
.confirm-header
.btn-quota
  //margin-bottom: 22px
  text-align: left
  color: #C0C4CC
  margin-top: 8px
  background-color: #FFF
  background-image: none
  border-radius: 4px
  border: 1px solid #DCDFE6
  box-sizing: border-box
  display: inline-block
  font-size: inherit
  height: 40px
  //line-height: 40px
  outline: 0
  padding: 0 15px!important
  transition: border-color .2s cubic-bezier(.645,.045,.355,1)
.btn-delete
  color: $redColor
  border: 1px solid $redColor

.label-slot
  margin-bottom: 0px !important
  text-align: left!important
  line-height: 21px!important
  font-weight: 600!important
  font-size: 18px!important
  width: 100% !important
  padding: 0 !important
  color: #909399!important
  &-date
    color: #555!important

.input-quota
  width: 100%
  margin-top: 8px
.btn-container
  display: flex
  justify-content: space-between
  align-items: center
  flex-direction: column
  margin-bottom: 16px
.drivers-slot-btn
  background: $color-blue-light
  width: 100%
  padding: 12px 0px
  height: 45px
i
  @media only screen and (max-width: 550px)
    &:before
      font-size: 14px!important
.no-quota
  display: block
  margin-top: 12px
  padding: 8px
  text-align: center
  background: rgba(239, 0, 9, 0.29)
  color: white
  border-radius: 4px
  margin-bottom: 24px

.confirm-modal
  width: 320px!important
  padding: 4px 4px 24px 4px
  label
    font-size: 13px
  .el-message-box__message
    font-size: 16px
  .confirmDelete
    background: $btn-orange
    border-color: $btn-orange
  .el-button
    font-size: 14px
  .el-message-box__message > p
    text-transform: none!important
.confirm-title
  text-align: center
  margin: 8px
  margin-bottom: 16px
  font-size: 18px
.confirm-input
  font-size: 16px !important
  line-height: $lh-16
.confirm-subtitle
  font-size: 16px !important
  line-height: $lh-16
  margin-bottom: 16px
  color: #333
  text-transform: none
.confirm-subtitle-btn
  display: block
  color: $btn-orange
  text-align: center
  font-size: $fs-14
  line-height: $lh-14
  font-weight: bold
.confirm-requireInn
  font-size: 18px
  font-weight: bold
  line-height: 1
  span
    color: #909399

.timer-slots
  margin: 16px 0px
  display: flex
  font-size: 16px !important
  justify-content: space-between
  align-items: center
  > span
    display: block
    color: $btn-orange
    font-weight: bold
</style>
