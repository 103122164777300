var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "confirm-slots" }, [
    _c(
      "div",
      [
        _c("time-left"),
        _c(
          "div",
          { staticClass: "confirm-header" },
          [
            _c(
              "iq-title",
              {
                class: {
                  desktopHeader: !_vm.isMobile,
                  mobileHeader: _vm.isMobile,
                },
              },
              [_vm._v(" Подтвердить тайм слоты: ")]
            ),
            _vm._m(0),
          ],
          1
        ),
        _vm.requireInn
          ? _c("div", { staticClass: "m-b-16 m-t-16 confirm-requireInn" }, [
              _c("div", { staticClass: "m-b-7" }, [
                _c("span", [_vm._v("ИНН поставщика/перевозчика: ")]),
                _vm._v(_vm._s(_vm.requireInn) + " "),
              ]),
              _c("div", [_c("i", [_vm._v(_vm._s(_vm.requireInnName))])]),
            ])
          : _vm._e(),
        _vm._l(_vm.getSloatPriceData, function (item, i) {
          return _c("div", { key: i }, [
            item["timeslot_id"]
              ? _c("div", { staticClass: "btn-container" }, [
                  _c("span", { staticClass: "label-slot" }, [
                    _c("span", [_vm._v(" " + _vm._s(`Авто №${i + 1}`) + " ")]),
                    _c("span", { staticClass: "label-slot-date" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.dateTimeslot(
                              item.timeslot.window_from
                            )} c ${_vm.timeTimeslot(
                              item.timeslot.window_from
                            )} до ${_vm.timeTimeslot(
                              item.timeslot.window_to
                            )} :`
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "slot-line" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn-quota btn-full",
                          attrs: {
                            disabled:
                              !!_vm.confirmSlot(item.timeslot_id) ||
                              !!_vm.deletedSlot(item.timeslot_id),
                            plain: "",
                            type: _vm.deletedSlot(item.timeslot_id)
                              ? "danger"
                              : _vm.getCarNumber(item.timeslot_id) ||
                                _vm.confirmSlot(item.timeslot_id)
                              ? "success"
                              : "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.routerPush(item.timeslot_id)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "el-icon-truck icon-btn" }),
                          !!_vm.deletedSlot(item.timeslot_id)
                            ? _c("span", [_vm._v("Запрос отменен")])
                            : _vm._e(),
                          !_vm.confirmSlot(item.timeslot_id) &&
                          !_vm.deletedSlot(item.timeslot_id)
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getCarNumber(item.timeslot_id) ||
                                        " Введите данные автомобиля"
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          _vm.confirmSlot(item.timeslot_id)
                            ? _c("span", [
                                _vm._v(
                                  " " + _vm._s("Тайм слот подтвержден") + " "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      !_vm.confirmSlot(item.timeslot_id)
                        ? _c(
                            "el-button",
                            {
                              staticClass: "btn-quota btn-icon btn-delete",
                              attrs: { type: "danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.openDelete(item.timeslot_id)
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-delete red-btn" })]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            item["state_system"] === "no_quota"
              ? _c("span", [
                  _c("span", [
                    _vm._v(
                      " " + _vm._s(`Авто №${i + 1} нет тайм слота `) + " "
                    ),
                  ]),
                  _c("span", { staticClass: "no-quota" }, [
                    _vm._v("Нет квоты"),
                  ]),
                ])
              : _vm._e(),
          ])
        }),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "btn-container" },
      [
        _vm.showConfirmButton
          ? _c(
              "iq-button",
              { attrs: { size: "fullWidth" }, on: { onClick: _vm.submitForm } },
              [_vm._v(" Подтвердить все тайм слоты ")]
            )
          : _vm._e(),
        _c(
          "div",
          { staticStyle: { "margin-top": "22px", width: "100%" } },
          [
            _c(
              "iq-button",
              {
                attrs: { "full-width": "", color: "second", size: "fullWidth" },
                on: { onClick: _vm.open },
              },
              [_vm._v(" Вернуться к поиску тайм слотов ")]
            ),
          ],
          1
        ),
        _vm.timeSlotBeforeSubmit.length > 0 && _vm.showConfirmModal
          ? _c("confirm-modal", {
              attrs: {
                "dialog-visible":
                  _vm.timeSlotBeforeSubmit.length > 0 && _vm.showConfirmModal,
                "dialog-data-slots": _vm.getSloatPriceData,
                "dialog-data": _vm.timeSlotBeforeSubmit,
              },
              on: { close: _vm.closeConfirmModal },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "confirm-subtitle" }, [
      _vm._v(" Добавьте авто и нажмите "),
      _c("span", { staticStyle: { color: "#eb6626" } }, [
        _vm._v('"Подтвердить тайм слоты"'),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }